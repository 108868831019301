import * as React from 'react';
import { ReactElement, useEffect } from 'react';
import { connect } from 'umi';
import { checkUserAccessToPath } from '@/utils/access';
import ForbiddenPage from '@/pages/403';
import { normalizePath } from '@/utils/pathUtils';
import { useHistory } from 'react-router-dom';

interface AccessControlWrapperProps {
  children?: ReactElement | null;
  route: any;
  isUserLoggedIn: boolean;
}

const AccessControlWrapper: React.FC<AccessControlWrapperProps> = ({
  children,
  route,
  isUserLoggedIn,
}: AccessControlWrapperProps) => {
  const { path } = route;
  const history = useHistory();

  useEffect(() => {
    if (!isUserLoggedIn) {
      history.push('/user/login');
    }
  }, [isUserLoggedIn, history]);

  if (!isUserLoggedIn) return null;
  if (checkUserAccessToPath(normalizePath(path))) return children;
  return <ForbiddenPage />;
};

export default connect(({ user }) => ({
  isUserLoggedIn: user.loggedIn,
}))(AccessControlWrapper);