import React from 'react';
import {connect, FormattedMessage as F, useIntl} from 'umi';
import { Popconfirm, Tag, Tooltip } from 'antd';
import type {ProColumns} from "@ant-design/pro-table";
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';

import MyPageContainer from "@/components/MyPageContainer";
import Table from "@/components/Table";
import { UserModel } from '@/typings/models/User';

const TRACKING_LABEL = "UserManagement"
const UserManagement: React.FC = ({ dispatch, list, loading }) => {
  const intl = useIntl();
 
  function fetchTableData(payload?: {search:string}) {
    dispatch({
      type: 'user/getAllUsers',
      payload,
    });
  }
  function toggleDrawer() {
    dispatch({
      type: "user/toggleDrawer"
    })
  }

  const columns: ProColumns<any>[] = [
    {
      title: <F id="pages.users.user" defaultMessage="User" />,
      dataIndex: 'firstname',
      render: (name, record) => `${record.firstname} ${record.lastname}`,
    },
    {
      title: <F id="pages.users.email" defaultMessage="Email" />,
      dataIndex: 'email',
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.users.role" defaultMessage="Role" />,
      dataIndex: 'role',
      render: (role: { role: string; label: string }) => <Tag color="blue">{role.label}</Tag>,
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.common.actions" />,
      render: (_, user: UserModel) => (
        <Popconfirm
          onConfirm={() => undefined}
          placement={'topRight'}
          title={
            <F
              id={'pages.users.removeUserConfirmation'}
              defaultMessage="Are you sure you eant to remove the user"
            />
          }
        >
          <a>
            <DeleteOutlined />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <MyPageContainer label={TRACKING_LABEL}>
      <Table
        headerTitle={intl.formatMessage({
          id: 'pages.users.tableTitle',
          defaultMessage: 'Users',
        })}
        rowKey={'id'}
        columns={columns}
        label={TRACKING_LABEL}
        dataSource={list?.map((item, id) => ({ ...item, id }))}
        loading={loading}
        request={fetchTableData}
        toolBarRender={() => (
          <Tooltip title={<F id={'pages.users.newUser'} defaultMessage={'New user'} />}>
            <UserAddOutlined onClick={toggleDrawer} />
          </Tooltip>
        )}
      />
    </MyPageContainer>
  );
};

export default connect(({user, loading}) => ({
  list: user.list,
  loading: loading.effects["user/getAllUsers"]
}))(UserManagement);
