import {changeUserSettingsRequest, createUserRequest, deleteUserRequest, fetchAllUsersRequest, fetchUserSettingsRequest} from "@/services/api/user";
import {processAPIResponse} from "@/utils/request";
import {BackofficeUserModel} from "@/typings/api/user";
import { trackModalView } from "@/services/tracker";

const initialState = {
  loggedIn: false,
  settings: {},
  list: [],
  drawer: {
    visible: false
  },
}

export default {
  namespace: 'user',

  state: initialState,

  effects: {
    * loggedIn({payload = {}}: { payload: BackofficeUserModel }, {put}) {
      const {User, Partner, Journeys, Role} = payload;
      yield put({
        type: 'gotMe',
        // TODO: Check Avatar
        payload: {
          user: {...User, avatar: "https://pbs.twimg.com/profile_images/1362000555820937217/fNKKSMBa_200x200.jpg"},
          partner: Partner,
          role: Role
        }
      })
      yield put({
        type: 'journey/gotJourneys',
        payload: Journeys
      })
      yield put({
        type: 'fetchSettings'
      })
    },
    * fetchSettings({payload = {}}, {call, put}) {
      const {response, data} = yield call(fetchUserSettingsRequest)
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'fetchedSettings',
            payload: data
          })
        },
        * onError() {

        }
      })
    },
    * create({payload}, {call, put, select}) {
      const { response, data } = yield call(createUserRequest, { payload });
    yield processAPIResponse({response, data}, {
      * onSuccess(data) {
        yield put({
          type: 'toggleDrawer',
          payload: {}
        })
        yield put({
          type: 'fetchedAllUsers',
        })
      }
    })

    },
    * delete({ payload: { userId, intl } }, { call, put }) {
      const { response, data } = yield call(deleteUserRequest, { params: { userId } });
      yield processAPIResponse({ response, data }, {
        * onSuccess() {
          yield put({
            type: "fetchedAllUsers"
          })
        },
        * onError(error) {
          message.error(error?.message)
        },
      })
    },
    * getAllUsers({ payload = {} }, { call, put }) {
    let query = {} ;
    if (payload.search){
      query.search = payload.search;
    }
      const { response, data } = yield call(fetchAllUsersRequest, query )
    yield processAPIResponse({response, data}, {
      * onSuccess(data) {
        yield put({
          type: 'fetchedAllUsers',
          payload: data
        })
      },
      * onError() {

      }
    })
  },
    // * changeSettings({payload = {data: {locale: ""}}}, {call, put}) {
    //   yield call(changeUserSettingsRequest, {
    //     payload: {
    //       locale
    //     }
    //   })
    //   yield put({
    //     type: 'fetchSettings',
    //   })
    // },
    * changeSettings({payload = { }}, {put,call}) {
      yield call(changeUserSettingsRequest, {
        payload: {
          locale: payload["locale"]
        }
      })
      yield put({
        type: 'fetchSettings',
      })
    },
    * resetPassword({payload = {}}, {put}) {
      yield put({
        type: 'resetPasswordResult',
        payload: {}
      })
    },
    * forgetPassword({payload = {}}, {put}) {
      yield put({
        type: 'forgetPasswordResult',
        payload: {}
      })
    }
  },
  reducers: {
    gotMe(state, action) {
      return {
        ...state,
        ...action.payload,
        loggedIn: true
      }
    },
    loggedOut() {
      return initialState
    },
    gotUserData(state) {
      return {
        ...state
      }
    },
    fetchedSettings(state, action) {
      return {
        ...state,
        settings: action.payload
      }
    },
    fetchedAllUsers(state, action) {
      return {
        ...state,
        list: action.payload
      }
    },
    changedSettings(state) {
      return {
        ...state
      }
    },
    resetPasswordResult(state) {
      return {
        ...state
      }
    },
    forgetPasswordResult(state) {
      return {
        ...state
      }
    },
    toggleDrawer(state, action) {
      const drawer = state.drawer || {};
        drawer.visible = !drawer.visible
        trackModalView('new-user')
      return {
        ...initialState,
        drawer
      }
    }
  },
};
