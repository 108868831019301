import get from 'lodash/get';

import { ROOMTAILOR, SUPER_ADMIN, getRolesForComponent, getRolesForPath } from '../typings/roleMapping';

const isConversionalAdminRoles = (role: string) => role === SUPER_ADMIN || role === ROOMTAILOR
const getUserRole: () => string = () => {
  const { user } = window.getState();
  return get(user, 'user.role.role', "");
}

export function checkUserAccessToPath(path: string): boolean {
  const userRole = getUserRole()
  if(!userRole) return false;
  if (isConversionalAdminRoles(userRole)) return true;
  const pathRoles = getRolesForPath(path)
  return pathRoles.includes('*') || pathRoles.includes(userRole);
}
export function checkUserAccessToComponent(component: string): boolean {
  const userRole = getUserRole()
  if(!userRole) return false;
  if (isConversionalAdminRoles(userRole)) return true;
  const componentRoles = getRolesForComponent(component)

  return componentRoles.includes('*') || componentRoles.includes(userRole);


}