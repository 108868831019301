export default {
  'menu.welcome': 'Bienvenido',
  'menu.forgotpassword': 'Contraseña olvidada',
  'menu.reports': 'Informes',
  'menu.reports.dashboard': 'Dashboard',
  'menu.reports.marketing': 'Marketing',
  'menu.reports.performance': 'Rendimiento',
  'menu.reports.cta': 'Call to Actions',
  'menu.Customers': 'Clientes',
  'menu.Requests': 'Solicitudes',
  'menu.Locations': 'Ubicaciones',
  'menu.Images': 'Fotos',
  'menu.Settings': 'Configuraciones',
  'menu.Storybook': 'StoryBook',
  'menu.Support': 'Servicio de asistencia',
  'menu.Language': 'Idioma',
  'menu.Billings': 'Facturas',
  'menu.more-blocks': 'Más módulos',
  'menu.home': 'Página de inicio',
  'menu.admin': 'Administrador',
  'menu.admin.sub-page': 'Subpágina',
  'menu.login': 'Inicio de sesión',
  'menu.register': 'Registro',
  'menu.register.result': 'Resultado del registro',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Análisis',
  'menu.dashboard.monitor': 'Pantalla',
  'menu.dashboard.workplace': 'Lugar de trabajo',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Formulario',
  'menu.UserManagement': 'Gestión de usuarios',

  // Don't pay attention to these phrases please Clemens. Thanks.
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
};
