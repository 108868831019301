import { useState } from 'react';
import { Avatar, Collapse, Menu, Popover } from 'antd';
import { FormattedMessage as F, useHistory } from 'umi';
import classNames from 'classnames';
import get from 'lodash/get';

import { useModel } from '@@/plugin-model/useModel';
import { getInitials } from '@/utils/functions';
import { logOut } from '@/services/auth';
import { checkUserAccessToPath } from '@/utils/access';
import { FOOTER_ROUTE_CONFIG } from './menuConfig';
import styles from './index.less';
const { Panel } = Collapse;

const UserMenuDropdown = ({ mini = false }: { mini?: boolean }) => {
  const [active, setActive] = useState(false);
  const history = useHistory();
  const { initialState } = useModel('@@initialState');

  function getUser() {
    try {
      return get(initialState, 'currentUser.User', {});
    } catch (e) {
      return {};
    }
  }

  function getUserName() {
    const User = getUser();
    return (User.firstname || '') + ' ' + (User.lastname || '');
  }

  function getAvatar() {
    const User = getUser();
    if (User.avatar) {
      return (
        <Avatar
          src={
            'https://preview.keenthemes.com/metronic-v4/theme_rtl/assets/pages/media/profile/profile_user.jpg'
          }
        />
      );
    } else {
      return <Avatar>{getInitials(getUserName())}</Avatar>;
    }
  }

  function getEmail() {
    const User = getUser();
    return User.email;
  }

  function onMenuClick(path: string) {
    setActive(false);
    if (path === '/user/login') {
      logOut();
    }
    history.push(path);
  }

  const menu = (
    <Menu className={styles.userMenuItems}>
      {FOOTER_ROUTE_CONFIG?.filter((route) => checkUserAccessToPath(route.path)).map((route) => (
        <Menu.Item key={route.name} onClick={() => onMenuClick(route.path)}>
          <route.icon />
          <F id={route.messageId} defaultMessage={route.defaultMessage} />
        </Menu.Item>
      ))}
    </Menu>
  );

  const header = (
    <div className={styles.userMenuHeader}>
      <div>{getAvatar()}</div>
      {!mini && (
        <div>
          <div className={styles.name} id={'auth-user-name'}>
            {getUserName()}
          </div>
          <div className={styles.email}>{getEmail()}</div>
        </div>
      )}
    </div>
  );

  if (!mini)
    return (
      <div className={styles.userMenu}>
        <Collapse
          defaultActiveKey={['1']}
          activeKey={active ? '1' : '0'}
          expandIconPosition={'right'}
          onChange={() => setActive(!active)}
        >
          <Panel header={header} key="1">
            {menu}
          </Panel>
        </Collapse>
      </div>
    );

  return (
    <Popover
      overlayClassName={styles.miniPopover}
      placement={'rightBottom'}
      content={<div className={styles.mini}>{menu}</div>}
    >
      <div className={classNames(styles.miniTitle)}>{header}</div>
    </Popover>
  );
};

export default UserMenuDropdown;
